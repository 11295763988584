import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  permission: [],
  permissionGroups: [],

  AllPermissions: [],
  AllPermissionGroupe: [],

  status: "idle",
  permissiongroupestatus: false,
  affectpermission: false,

  testcheck: false,
  testcheckPermissionGroup: false,

  error: null,
  ckekedState: false,
};

export const getAllPermissions = createAsyncThunk(
  "permission/getAllPermissions",
  async () => {
    let data;
    try {
      const response = await axios.get(`/permissions`);

      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllPermissionGroupe = createAsyncThunk(
  "permission/getAllPermissionGroupe",
  async () => {
    let data;
    try {
      const response = await axios.get(`/permission-group`);

      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getOneGroupPermissions = createAsyncThunk(
  "permission/getOneGroupPermissions",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`/permission-group/${id}`);

      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addPermission = createAsyncThunk(
  "permission/addPermission",
  async ({ code, label, thunkAPI }) => {
    let data;
    try {
      const response = await axios.post(`/permissions`, { code, label });
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllPermissions());

        return data;
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addPermissionGroup = createAsyncThunk(
  "permission-group`/addPermissionGroup",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/permission-group`, body);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllPermissionGroupe());
        return {
          data: data,
          message: "SUCCESS !Permission Group added succesfully ",
        };
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updatePermissionGroup = createAsyncThunk(
  "permission-group`/updatePermissionGroup",
  async (body, thunkAPI) => {
    const { permissions, id } = body;
    let data;
    try {
      const response = await axios.patch(`/permission-group/update/${id}`, {
        permissions,
      });
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllPermissionGroupe());
        return {
          data: data,
          message: "SUCCESS ! Permission Group has been successfully updated !",
        };
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deletePermissionGroup = createAsyncThunk(
  "permission-group`/updatePermissionGroup",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/permission-group/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllPermissionGroupe());

        return {
          message: "SUCCESS ! Permission Group has been successfully deleted !",
        };
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deletePermission = createAsyncThunk(
  "permission/deletePermission",
  async (id) => {
    let data;
    try {
      const response = await axios.patch(`/permissions/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    pushPermission: (state, action) => {
      state.permission.includes(action.payload)
        ? (state.permission = state.permission.filter(
            (item) => item !== action.payload
          ))
        : state.permission.push(action.payload);
      state.permission = state.permission.filter((el) => el && el);
      state.affectpermission = true;
    },
    affectPermissionState: (state, action) => {
      state.testcheck = action.payload;
    },
    affectPermissionGroupState: (state, action) => {
      state.testcheckPermissionGroup = action.payload;
    },

    pushPermissionGroupe: (state, action) => {
      state.permissionGroups = action.payload;
      state.permissiongroupestatus = true;
    },
  },
  extraReducers: {
    [getAllPermissions.pending]: (state) => {
      state.status = "loading";
    },
    [getAllPermissions.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.AllPermissions = action.payload;
    },
    [getAllPermissions.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [getAllPermissionGroupe.pending]: (state) => {
      state.permissionsGroupeStatus = "loading";
    },
    [getAllPermissionGroupe.fulfilled]: (state, action) => {
      state.permissionsGroupeStatus = "succeeded";
      state.AllPermissionGroupe = action.payload;
    },
    [getAllPermissionGroupe.rejected]: (state, action) => {
      state.permissionsGroupeStatus = "failed";
      state.error = action.payload;
    },
  },
});
export const {
  pushPermission,
  pushCheckedPermissions,
  pushPermissionGroupe,
  resetPermissionArray,
  affectPermissionState,
  affectPermissionGroupState,
  deletePermissionGroupe,
} = slice.actions;

export const reducer = slice.reducer;
export default slice;
